body{
  background-color: #ff7d2a;
  font-family: 'Roboto', sans-serif;
}
header {
  background-color: #ff7d2a;
  line-height: 55px;
  padding: 20px;
}
img {
  max-width: 100%;
  height: auto;
}
.headings {
  background-color: #ff7d2a;
  color: #fff;
  padding: 60px 0px;
  text-align: left;
}
.content {
  padding: 60px 0px 0px;
}
.content h2 {
  margin: 30px 0px;
}
.block_content1, .block_content2 {
  padding: 50px 0px;
}
.block_content1 h3 {
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 40px;
}
.block_content1 h3:after {
  content: "";
  height: 2px;
  width: 150px;
  position: relative;
  display: block;
  left: 0;
  top: 20px;
  border-top: 2px solid #ff7d2a;
}
.block_content1 img {
  width: 100%;
  margin-top: 30px;
}
.block_content2 {
  background-color: #f1f1f1;
}
.block_content2 h3 {
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 40px;
}
.block_content2 h3:after {
  content: "";
  height: 2px;
  width: 150px;
  position: relative;
  display: block;
  left: 0;
  top: 20px;
  border-top: 2px solid #ff7d2a;
}
.block_content2 img {
  width: 100%;
}
.pasi {
  padding: 80px 0;
  text-align: center;
}
.pasi h2 {
  font-size: 2em;
}
.pas {
  text-align: center;
  padding: 30px 0px;
  margin-bottom: 60px;
}
.pas h3 {
  color: #ff7d2a
}
.pas svg {
  color: #ff7d2a;
  margin-bottom: 40px;
}
footer {
  background-color: #172541;
  color: #fff;
  padding: 80px 20px;
}
.modul {
    text-align: center;
    margin: 30px 0px;
}
.modul img {
  border: 1px solid #f1f1f1;
  width: 100%;
}
footer h3 {
  font-size: 1.2rem;
  padding-bottom: 20px;
  border-bottom: 1px solid #e77128;
}
footer ul {
  padding: 0;
}
footer li {
  list-style-type: none;
  padding: 10px 0;
}
footer a {
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
}
footer a:hover {
  color: #ff7d2a;
}
footer svg {
  margin-right: 15px;
}
.navbar-brand {display: none;}
.navbar {
  background-color: transparent;
}
.navbar-expand-md .navbar-nav .nav-link {
  color: #fff;
}
a#offcanvasNavbarDropdown-expand-md.dropdown-toggle.nav-link {
  color: #fff;
}
.navbar-expand-md a.nav-link.active{
  color: #ddd;
}
a.dropdown-item {
  color: #fff;
}
.dropdown-menu.show {
  background-color: #172541;
}
.navbar-toggler {
  border: 0;
}
span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  right: 0;
  margin-top: 9px;
}
button.btn.btn-primary.theme {
  background-color: transparent;
  border: none;
  margin-left: 20px;
}
svg.bi.bi-telephone-inbound {
  margin-right: 10px;
}
a.btn.btn.btn-outline-light.whatsapp {
  border: 0;
  margin-left: 15px;
}
.model {
  line-height: 3rem;
  margin: 60px 0px;
  text-align: center;
}
.model img {
  width: 100%;
}
.model img:hover {
  margin-top: -20px;
  transition: 0.9s;
}
.pachet_business {
  text-align: center;
}
.pachet_business h2 {
  color: #ff7d2a;
  font-weight: 800;
}
.pachet_business span {
  background-color: #172541;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  position: relative;
}
.pachet_business ul {
  margin-top: 30px;
}
.pachet_business li {
  border-bottom: 1px solid #f1f1f1;
  list-style-type: none;
  padding: 10px;
}
.pachet_premium {
  text-align: center;
  margin-bottom: 50px;
}
.pachet_premium h2 {
  color: #172541;
  font-weight: 800;
}
.pachet_premium span {
  background-color: #ff7d2a;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  position: relative;
}
.pachet_premium ul {
  margin-top: 30px;
}
.pachet_premium li {
  border-bottom: 1px solid #f1f1f1;
  list-style-type: none;
  padding: 10px;
}
.intro {
  text-align: center;
}
.intro h2 {
  font-size: 2em;
  margin-bottom: 50px;
}
.intro h2::after {
  content: "";
  height: 2px;
  width: 150px;
  position: relative;
  display: block;
  left: 0;
  top: 20px;
  border-top: 2px solid #ff7d2a;
  margin: 0px auto;
}
.avantaje.container {
  padding: 80px 0px;
}
.avantaje img {
  margin-top: 130px;
  width: 100%;
}
.avantaje h3 {
  color: #ff7d2a;
  border-bottom: 1px solid #f1f1f1;
  font-weight: 700;
  margin-top: 25px;
  padding-bottom: 20px;;
}
.contact img {
  width: 100%;
  margin: 50px 0px;
}
@media (min-width: 768px) {
  .navbar.navbar-expand-md.navbar-light {
    margin: 3px 0px;
    padding: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0px 20px;
    margin: 0px 7px
  }
  .navbar-expand-md {
    padding: 0;
  }
}
@media (max-width: 768px) {
  button.navbar-toggler {
    border: 0;
    right: 0px;
    position: absolute;
    top: -50px;
  }
  a#offcanvasNavbarDropdown-expand-md.dropdown-toggle.nav-link {
    color: #000;
    border-bottom: 1px solid #f1f1f1;
  }
  .nav-link {
    border-bottom: 1px solid #f1f1f1;
  }
  .col-lg-3.col-md-4.order-last {
    text-align: center;
  }
  .offcanvas.show:not(.hiding), .offcanvas.showing {
    border: 0;
  }
  .pachet_premium h2 {
    border-top: 1px solid #f1f1f1;
    margin-top: 50px;
  }
  .avantaje {
    text-align: center;
  }
  .avantaje img {
    margin-top: 0px;
  }
}