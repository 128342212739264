.calltoaction {
    background-color: #18294a;
    color: #fff;
    text-align: center;
    padding: 80px 20px;
}

.calltoaction h5 {
    font-size: 2rem;
    margin-bottom: 30px;
}
