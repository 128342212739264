.about {
    background-color: #ff7d2a;
    color: #fff;
    padding: 80px 20px;
}

.about h3 {
    margin: 25px 0px 40px;
    font-size: 2.1rem;
}

.about img {
    width: 100%;
}

blockquote {
    background-color: #fff;
    color: #000;
    margin: 20px 0 0 5px;
    padding: 30px 40px;
}

@media (max-width: 768px) {
    .about {
        text-align: center;
    }
    .about img {
        margin-bottom: 30px;
    }
    counter.container {
        padding:0;
    }
    .col-lg-3.col-md-12.order-last {
        text-align: center;
    }
}