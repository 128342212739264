.banner {
    background-color: #ff7d2a;
    color: #fff;
    padding: 5rem 0 12rem;
}

.banner h1 {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
}

.banner img {
    width: 100%
}

.banner.button {
    margin-top: 5.5rem;
}
svg.bi.bi-arrow-right {
    margin-left: 10px;
}

@media (max-width: 768px) {
    .banner {
      text-align: center;
      padding: 5rem 0 6rem;
    }
    .banner h1 {
      font-size: 2.5rem;
  }
    .banner img {
        margin-top: 30px;
    }
}