.blocks {
    background-color: #172541;
    color: #fff;
}

.personalizare {
    background-color: #343675;
}

.personalizare:hover {
    background-color: #2f3069;
}

.responsive {
    background-color: #393f91;
}

.responsive:hover {
    background-color: #2f3069;
}

.optimizare {
    background-color: #21225f;
}

.optimizare:hover {
    background-color: #1e1f55;
}

.personalizare, .responsive, .optimizare  {
    color: #fff ;
    display: inline-flex;
    margin-top: -100px;
    margin-bottom: 80px;
    padding: 70px 25px 50px;
}

.personalizare, .responsive, .optimizare, h2 {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2rem;
}

.info_blocks {
    text-align: center;
    color: #e8e9ff;
    margin-bottom: 80px;
}

.info_blocks svg {
    color: #ff7d2a;
    margin: 40px 0px;
}

.info_blocks:hover svg {
    fill: #fff;
}
  
.info_blocks h3 {
    border-bottom: 1px solid #474885;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 35px;
    padding-bottom: 20px;
}

@media (max-width: 768px) {
    .personalizare, .responsive, .optimizare {
        color: #fff;
        display: block;
        text-align: center;
        margin: 0px;
        padding: 28px;
    }
    .info_blocks {
        margin-bottom: 20px;
    }
}