.clients {
    background-color: #ff7d2a;
    color: #fff;
    padding: 80px 20px;
    text-align: center;
}
.clients h4 {
    font-size: 2rem;
    margin-bottom: 50px;
}
.clients img {
    width: 100%;
    padding: 1%;
}
.clients img:hover {
    margin-top: -20px;
    transition: 0.9s;
}
.suna_acum {
    margin-top: 50px;
}

@media (max-width: 768px) {
    .clients img {
        margin-bottom: 30px;
    }
    .headings {
        text-align: center;
    }
}