.avantages {
    background-color: #172541;
    border-top: 1px solid #1f3155;
    color: #fff;
    padding: 80px 20px;
    text-align: center;
}

.avantages h3 {
margin-bottom: 40px;
}

.avantages svg, p {
    margin-bottom: 5px;
    margin-top: 20px;
}

.avantages img {
    width: 100%;
    height: auto;
    margin-top: 40px;
}

.avantages_block {
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .avantages p {
       padding: 0px 30px;
    }
}