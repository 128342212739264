.odometers {
    background-color: #ff7d2a;
    color: #fff;
    font-size: 1.2rem;
    padding-bottom: 35px;
    text-align: center;
}

.odometers span {
    font-size: 1.7rem;
}

.odometers svg {
    margin-right: 15px;
    margin-bottom: 15px;
}